<template>
  <div class="text-center">
    <PaperLogo />
    <h2 class="headline-xs !font-normal">
      {{ $t('trialSignup.waitingPeriod.title') }}
    </h2>

    <i18n-t
      class="p-5"
      :keypath="
        papers.length > 1
          ? 'trialSignup.waitingPeriod.bodyMultiple'
          : 'trialSignup.waitingPeriod.bodyOne'
      "
      tag="p"
      scope="global"
    >
      <span>{{
        true
          ? siteConfig.name
          : $formatList(
              papers.map((paper) => `${siteConfig.name} ${paper.Name}`)
            )
      }}</span>
    </i18n-t>

    <h3 class="font-medium font-sans text-xl md:text-2xl py-4">
      {{ $t('trialSignup.waitingPeriod.needOffer') }}
    </h3>
    <button
      class="ml-2 mb-3 text-white article-label !font-medium py-4 px-6"
      :class="
        true
          ? 'rounded-md bg-black transform translate-y-px'
          : 'rounded-full bg-blue'
      "
      @click="$emit('call-me')"
    >
      {{ $t('trialSignup.waitingPeriod.button') }}
    </button>
    <div v-if="!true" class="bg-gray-100 -ml-6 -mr-6 -mb-6 p-6">
      <div class="text-sm font-sans font-semibold leading-normal md:mx-2">
        {{ $t('trialSignup.waitingPeriod.tryAnother') }}
      </div>
      <div
        class="flex flex-wrap justify-center items-center content-center gap-2 py-3"
      >
        <template
          v-for="(p, index) in papers[0].PaperRecomendation"
          :key="index"
        >
          <CommonButton
            class="text-black bg-white border-gray-300 article-label !font-medium py-4 px-6"
            :class="
              true
                ? 'rounded-md transform translate-y-px'
                : 'rounded-full'
            "
            @click="navigate('/' + p.Url)"
          >
            {{ p.Name }}
          </CommonButton>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContentPaper } from '~/typesManual/content_api/paper'

const siteConfig = useSiteConfig()
const router = useRouter()

// Declare props
defineProps<{ papers: ContentPaper[] }>()
const emit = defineEmits(['modal-close', 'call-me'])

const navigate = (to: string) => {
  emit('modal-close')
  router.push({ path: to })
}
</script>
