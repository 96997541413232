<template>
  <CommonLoading v-if="loading" />
  <form
    v-else
    id="form_trial_signup_callme"
    ref="callMeForm"
    class="ml-auto mr-auto text-center grid gap-4"
    :class="{
      'w-full md:w-96': !true,
      'w-fit h-full': true,
    }"
    @submit.prevent="submit"
  >
    <p
      v-if="true"
      class="mx-auto text-sm text-gray-800 leading-snug mb-4"
    >
      Vi ringer til dig med et uforpligtende tilbud på Mandag Morgen. Det står
      dig altså frit for, om du vil acceptere tilbuddet.
    </p>
    <p
      v-else-if="false"
      class="mx-auto text-sm text-gray-800 leading-snug mb-4"
    >
      {{ $t('trialSignup.callMeFormInfo') }}
    </p>

    <FormAlert v-if="alert" :type="alert.type">
      {{ alert.message }}
    </FormAlert>

    <FormInput
      ref="nameinput"
      v-model="name"
      required
      placeholder="NameHelpText"
      label="Name"
    />

    <FormInput
      ref="emailinput"
      v-model="email"
      type="email"
      required
      placeholder="Email"
      label="Email"
    />

    <FormInput
      ref="phoneinput"
      v-model="phone"
      type="tel"
      required
      placeholder="Phone"
      :pattern="siteConfig.formValidation?.phonePattern"
      label="Phone"
    />
    <FormInput
      v-if="true"
      ref="titleinput"
      v-model="title"
      required
      placeholder="JobTitle"
      label="JobTitle"
    />
    <FormInput
      v-if="true"
      ref="companyinput"
      v-model="company"
      required
      placeholder="Company"
      label="Company"
    />

    <div class="flex items-start justify-start">
      <CommonPermissionBox
        :isNewsletter="true ? true : false"
        class="text-left mt-1"
        :permissionGiven="marketingPermissionGiven"
        @update:permission="updateMarketingPermission"
      />
    </div>

    <CommonButton
      :bg="true ? 'bg-black' : 'bg-blue'"
      text="text-white"
      border="border-none"
      size=""
      class="w-full cursor-pointer py-3 px-4 article-label !font-medium"
      :class="
        true
          ? 'rounded-md transform translate-y-px'
          : 'rounded-full hover:bg-denimblue'
      "
      :disabled="!callMeForm?.checkValidity()"
    >
      {{ true ? 'Ring mig op' : $t('ads.callMe') }}
    </CommonButton>
  </form>
</template>

<script setup lang="ts">
import type { AlertBundle } from '~/typesManual/alert'

const nuxtApp = useNuxtApp()
const siteConfig = useSiteConfig()
const gtm = useGtm()
const { nwCookie } = useNwCookie()

const props = withDefaults(
  defineProps<{
    eventLabel?: string
    newsletter?: string | number
    requestedProducts?: string[] // Used for MM only, define the requested product for the marketing (will be sent in letter to marketing)
    papers?: number[] // For subscription page, when user choose several niches and click "Få et uforpligtende tilbud"
  }>(),
  {
    eventLabel: 'FreeNewsletterSignup',
    newsletter: 1, // 1 = the free frontpage newsletter.
  }
)

const name = ref('')
const email = ref('')
const phone = ref('')
const title = ref('')
const company = ref('')
const loading = ref(false)
const marketingPermissionGiven = ref(false)
const callMeForm = ref<HTMLFormElement>()

const emit = defineEmits(['complete', 'signed-up'])

const alert = ref<AlertBundle | undefined>(undefined)

async function submit() {
  loading.value = true
  alert.value = undefined

  try {
    await nuxtApp.$api.leads.trialLead({
      customerName: name.value,
      customerEmail: email.value,
      customerTelephoneNumber: phone.value,
      customerCompany: company.value,
      customerJobTitle: title.value,
      papers: props.papers,
      products: props.requestedProducts,
      ctaSignUpUrl: window.location.href,
    })

    emit('complete')
  } catch (error) {
    alert.value = {
      type: ALERT_TYPE.ERROR,
      message: (error as { response: { data: string } })?.response?.data, // The way this error message is taken is messy and confusing.
    }
  }
  loading.value = false

  if (marketingPermissionGiven.value) {
    // Subscribe to free newsletter for mm.dk: temporary solution, until subbscriptions will be moved to Iteras
    if (true) {
      const { err } = await nuxtApp.$api.user.subscribeFreeNewsletter(
        email.value,
        FREE_NEWSLETTER_ID
      )

      if (err) {
        console.error(err.message, ': ', err.cause)
      } else {
        emit('signed-up')

        gtm?.trackEvent({
          event: 'formTracking',
          eventCategory: 'Form',
          eventAction: 'Form Completed',
          eventLabel: props.eventLabel,
        })
        nwCookie.value = true
      }
    }
    const { firstName, lastName } = splitUserName(name.value || '')
    const { err } = await nuxtApp.$api.user.addMarketingPermission({
      firstName: firstName,
      lastName: lastName ? lastName : null,
      email: email.value,
      phone: phone.value,
      company: true ? company.value : undefined,
      jobTitle: true ? title.value : undefined,
      permissionSource: 'Lead Form',
      permissionSourceUrl: window.location.href,
    })
    if (err) {
      console.error(err.message, ': ', err.cause)
    }
  }
}

const updateMarketingPermission = (newValue: boolean) => {
  marketingPermissionGiven.value = newValue
}
</script>
